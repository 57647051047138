$color-alpha: #b78846;

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  &:before, &:after {
    box-sizing: border-box;
  }
}

.box {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	background-color: rgba(#000, 0.5);
	width: 100%;
	max-width: 600px;
	padding: 5px;
	border: 2px solid $color-alpha;
	&:before, &:after {
		content: "•";
		position: absolute;
		width: 14px;
		height: 14px;
		font-size: 14px;
		color: $color-alpha;
		border: 2px solid $color-alpha;
		line-height: 12px;
		top: 5px;
		text-align: center;
	}
	&:before {
		left: 5px;
	}
	&:after {
		right: 5px;
	}
	.box-inner {
		position: relative;
		border: 2px solid $color-alpha;
		padding: 40px;
		&:before, &:after {
			content: "•";
			position: absolute;
			width: 14px;
			height: 14px;
			font-size: 14px;
			color: $color-alpha;
			border: 2px solid $color-alpha;
			line-height: 12px;
			bottom: -2px;
			text-align: center;
		}
		&:before {
			left: -2px;
		}
		&:after {
			right: -2px;
		}
	}
}

.userinfo-block {
	width: 100%;
	border: 7px double #94877f;
	background-color: #000;
	color: #e6af14;
	padding: 10px 15px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

	.big-text {
		font-size: 1.7rem;
		font-weight: bold;
	}

	.info-text {
		font-size: 1rem;
		color: #fff;
		margin-bottom: 2px;
	}

	.text-normal{
		font-size: 1.2rem;
		color: #fff;
		font-weight: 300;
		border: 2px solid #fff;
		border-radius: 5px;
		}
}

.item-name-normal {
	color: #fff!important;
}

.item-name-normal.reverse {
	color: #000!important;
}

.item-name-blue {
	color: #1E90FF!important;
}

.item-name-rare {
	color: #FFD700!important;
}

.item-name-rare.reverse {
	font-weight: bold;
	color: #ffa600c2!important;
}

.rare-star {
	position: absolute;
	top: 0;
	left: 0;
}

.item-selected {
	border: 3px solid #FFD700;
}

.disabled-item{
	cursor:not-allowed;
}

.enabled-item{
	cursor:pointer;
}

.status-block{
	position: absolute;
	bottom: 4px;
	right: -3px;
}