.detail {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  .avatar {
    background: #87d068;
  }
  .userName {
    color: #ffffff;
    text-transform: capitalize;
    padding-left: 0.5rem !important;
    margin-bottom: 0px;
  }
}
